<template>
  <div class="table">
    <div class="tablebox">
      <el-table
        :data="
          tableData
        "
        style="width: 100%"
        border
      >
        <el-table-column
          prop="approvalDate"
          label="审批时间"
        >
        </el-table-column>
        <el-table-column prop="updateUserName" label="申请人"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="deptName" label="执法部门"> </el-table-column>
        <el-table-column prop="lawType" label="处罚类型"> </el-table-column>
        <el-table-column prop="rejectionReason" label="拒绝原因">
        </el-table-column>
        <el-table-column prop="approvalResult" label="是否通过">
        </el-table-column>
        <!-- <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="日期">
          <el-input v-model="formLabelAlign.date"></el-input>
        </el-form-item>
        <el-form-item label="执法人">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="执法企业">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
        <el-form-item label="执法内容">
          <!-- <el-input v-model="formLabelAlign.content"></el-input> -->
          <vue-tinymce v-model="content" :setting="setting" />
          <div class="submit_btn" @click="handleSubmit()">提交按钮</div>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetsysLawEnforcementRecord } from "@/request/api";
export default {
  name: "Htgltableoption",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        date: "",
        name: "",
        type: "",
        content: "",
      },
      currentPage: 1, //分页
      pageNum:1,
      pageSize: 10,
      total:1,
      checked: false,
      content: "", //富文本绑定值，点击提交按钮可以得到富文本插件的值
      setting: {
        menubar: true, //是否显示菜单栏
        toolbar:
          "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |", //富文本插件的工具集成
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar:
          "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen quickbars",
        language: "zh_CN", //富文本语言
        height: 500, //富文本的高度
        branding: false, //隐藏右下角官方超链接
        // 上传图片
        // images_upload_handler: (blobInfo, success, failure) => {
        //   //自定义上传图片方法
        //   let formdata = new FormData();
        //   formdata.append("file", blobInfo.blob(), blobInfo.filename());
        //   axios({
        //     url: "接口请求地址",
        //     method: "post",
        //     data: formdata,
        //     headers: {
        //       //可配置的请求头
        //       //             authorization: localStorage.getItem('token'),
        //       //             platform: '42',
        //       //             datetime: timestamp,
        //       //             encryption: md5(timestamp + '' +'42sokonmall')
        //     },
        //     processData: false, // 告诉axios不要去处理发送的数据(重要参数)
        //     contentType: false, // 告诉axios不要去设置Content-Type请求头
        //   }).then((res) => {
        //     success(res.data.data.url); //这里是接口返回的图片地址
        //   });
        // },
        // 上传文件和视频
        file_picker_callback: function (callback, value, meta) {
          //文件分类
          let filetype =
            ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
          //为不同插件指定文件类型及后端地址
          switch (meta.filetype) {
            case "image":
              filetype = ".jpg, .jpeg, .png, .gif";
              break;
            case "media":
              filetype = ".mp3, .mp4";
              break;
            case "file":
            default:
          }
          let input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", filetype);
          input.click();
          // input.onchange = function () {
          //   let file = this.files[0];
          //   console.log(file);
          //   let formdata = new FormData();
          //   formdata.append("file", file);
          //   axios({
          //     url: "后端接口地址",
          //     method: "post",
          //     data: formdata,
          //     headers: {
          //       //可配置的请求头
          //       //           authorization: localStorage.getItem('token'),
          //       //             platform: '40',
          //       //           datetime: timestamp,
          //       //           encryption: md5(timestamp + '' +'42sokonmall')
          //     },
          //     processData: false, // 告诉axios不要去处理发送的数据(重要参数)
          //     contentType: false, // 告诉axios不要去设置Content-Type请求头
          //   }).then((res) => {
          //     console.log(res.data.data);
          //     // callback(res.data.data.filePath); 这里填入接口返回的地址，
          //   });
          // };
        },
      },
      deta: "",
    };
  },

  methods: {
    handleUpdate(v) {
      this.dialogVisible = true;
      this.formLabelAlign.name = v.name;
      this.formLabelAlign.date = v.date;
      this.formLabelAlign.type = v.address;
    },
    handleDelete(v) {
      // console.log(v);
    },

    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // handleSubmit() {
    //   console.log(this.content, "898989");
    // },

    // 执法列表
    seelist() {
      GetsysLawEnforcementRecord({
        keyword: "",
        name: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
}
.vue_richtext {
  background-color: #fff;
  .submit_btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff;
    background-color: #eb5d20;
    text-align: center;
    margin: 20px auto 0;
    cursor: pointer;
  }
}
.block {
  text-align: center;
  margin-top: 15px;
}
</style>